<template>
  <v-card>
    <v-card-text>
      <h2>Successfully placed Order!</h2>
      <p class="mt-3 mb-10">
        An <b>Order</b> has successfully been placed and will be fulfilled and/or shipped within the next 2 business
        days.
      </p>
      <v-btn color="info" @click="backToDashboard()">Go back to dashboard</v-btn>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "GiftCardsSuccess",
  metaInfo: {
    title: "Gift Cards Order Placed"
  },
  methods: {
    backToDashboard() {
      this.$router.push({ name: "dashboard" });
    }
  }
};
</script>
